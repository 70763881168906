@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  /* styles.css */
  .CollapsibleContent {
    overflow: hidden;
  }

  .CollapsibleContent[data-state='open'] {
    animation: slideDown 300ms ease-out;
  }

  .CollapsibleContent[data-state='closed'] {
    animation: slideUp 300ms ease-out;
  }

  @keyframes slideDown {
    from {
      height: 0;
    }

    to {
      height: var(--radix-collapsible-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-collapsible-content-height);
    }

    to {
      height: 0;
    }
  }

  /* Prevent focus zoom on mobile devices */
  @media screen and (max-width: 767px) {

    input,
    select,
    textarea {
      font-size: 16px !important;
    }
  }
}

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .faded-bottom {
    @apply after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:hidden after:h-32 after:w-full after:bg-[linear-gradient(180deg, _transparent_10%, _hsl(var(--background))_70%)] after:md:block;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  html {
    @apply overflow-x-hidden;
  }

  body {
    @apply min-h-svh w-full bg-background text-foreground;
  }
}

.glide__arrow {
  background-color: #054bb4 !important;
  border-radius: 120px !important;
}


.slick-slide>div {
  margin: 0 10px !important;
  display: flex;
  justify-content: center;
}


/* Custom styles for the previous arrow */
.slick-prev {
  left: 0px !important;
  z-index: 1 !important;
  background-color: hsla(0, 0%, 47%, 0.5);
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
}


.slick-prev:before {
  font-size: 40px !important;
  color: black !important;
  content: '←';
}


/* Custom styles for the next arrow */
.slick-next {
  right: 0px !important;
  z-index: 1 !important;
  background-color: hsla(0, 0%, 47%, 0.5);
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;


}


.slick-next:before {
  font-size: 40px !important;
  color: black !important;
  content: '→';

}

#zmmtg-root {
  display: none;
  min-width: 0 !important;
}

.Link_module_link_5efef3b8 .VimeoLogoLink_module_vimeoLogo3af3d0e7 .shared_module_focusable_285596c9 {
  display: none !important;
}

/* Button base styles */
.custom-btn {
  border: 2px solid #054BB4;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  font-weight: 500;
  transition: all 0.3s ease;
  background-color: white;
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  position: relative;
  cursor: pointer;
}

/* Background animation span */
.custom-btn-bg {
  width: 14rem;
  height: 12rem;
  border-radius: 0.5rem;
  background-color: #054BB4;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(100%) translateY(100%);
  transition: all 0.5s ease;
  z-index: 0;
}

/* Hover state for background animation */
.custom-btn:hover .custom-btn-bg {
  transform: translateX(0) translateY(0);
  margin-left: 0;
  margin-bottom: 0;
}

/* Button text styles */
.custom-btn-text {
  position: relative;
  z-index: 1;
  width: 100%;
  text-align: left;
  color: #054BB4;
  transition: color 0.3s ease;
}

/* Hover state for the text color */
.custom-btn:hover .custom-btn-text {
  color: white;
}

/* Base button styles */
.custom-btn-2 {
  border: 2px solid #054BB4;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  font-weight: 500;
  transition: all 0.3s ease;
  background-color: #054BB4;
  border-radius: 9999px;
  padding: 0.5rem 1.5rem;
  position: relative;
  cursor: pointer;
}

/* Background span inside the button */
.custom-btn-2-bg {
  width: 14rem;
  height: 12rem;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0.5rem;
  transform: translateX(100%) translateY(100%);
  transition: all 0.5s ease-out;
  margin-bottom: 2.25rem;
  margin-left: 2.25rem;
  z-index: 0;
}

/* Hover effect for the background span */
.custom-btn-2:hover .custom-btn-2-bg {
  transform: translateX(0) translateY(0);
  margin-left: 0;
  margin-bottom: 0;
}

/* Button text styling */
.custom-btn-2-text {
  position: relative;
  width: 100%;
  text-align: left;
  color: white;
  transition: color 0.3s ease-in-out;
  z-index: 1;
}

/* Hover effect for button text */
.custom-btn-2:hover .custom-btn-2-text {
  color: #054BB4;
}

/* Custom scrollbar for WebKit browsers */
::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
  border-radius: 8px;
  /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
  background: #dbeafe;
  /* Color of the scrollbar thumb */
  border-radius: 8px;
  /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #dbeafe;
  /* Color on hover */
}

/* For Firefox (experimental) */
.scrollable-content {
  scrollbar-width: thin;
  /* Thin scrollbar */
  scrollbar-color: #dbeafe #f1f1f1;
  /* Thumb and track colors */
}

/* Perspective to enable 3D effect */
.perspective {
  perspective: 1000px;
  /* Adjust perspective for 3D depth */
}

.transform-style {
  transform-style: preserve-3d;
  /* Ensure 3D children are preserved */
}

.group-hover\:rotate-y-180:hover {
  transform: rotateY(180deg);
  /* Trigger the flip on hover */
}

.rotate-y-180 {
  transform: rotateY(180deg);
}

.backdrop-blur-md {
  backdrop-filter: blur(8px);
  /* Adds a background blur effect */
}

.absolute {
  backface-visibility: hidden;
  /* Hides the "back" when facing front */
}

/* CSS for horizontal scrolling with hidden scrollbar */
.scrollable-tabs {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

.scrollable-tabs::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
}

.scrollable-tabs button {
  display: inline-block;
  padding: 10px 16px;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
}

/* Custom scrollbar for Webkit browsers */
.scrollable-tabs::-webkit-scrollbar {
  height: 8px;
  /* Height of the scrollbar */
}

.scrollable-tabs::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  /* Color of the thumb */
  border-radius: 10px;
}

.scrollable-tabs::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
  /* Background color of the track */
}
